<template>
  <div class="activity-managers" v-if="canView">
    <page-header :title="title" icon="fa fa-people-line" :links="pageLinks">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="showAgenda">
      <div>
        <div class="school-years" style="margin-bottom: 10px;">
          <div v-if="schoolYears.length === 0">Aucune année scolaire sélectionnée</div>
          <div class="badge badge-dark" v-for="schoolYear of schoolYears" :key="schoolYear.id">
            {{ schoolYear.name }}
          </div>
        </div>
        <b-row>
          <b-col cols="3">
            <table class="table table-striped">
              <tr v-for="manager of activityManagers" :key="manager.id">
                <td>
                  <a href @click.prevent="selectManager(manager)">
                    {{ manager.individual.lastAndFirstName() }}
                  </a>
                </td>
              </tr>
            </table>
          </b-col>
          <b-col>
            <div v-show="selectedManager" class="selected-manager" ref="excelMe">
              <div ref="printMe">
                <b v-if="selectedManager">{{ selectedManager.individual.firstAndLastName() }}</b>
                <agenda-dates-table
                  :manager="selectedManager"
                  :show-header="false"
                  :force-add-event="forceAddEvent"
                  :force-change-places="forceChangePlace"
                  :delete-change-places="deleteChangePlaces"
                  :force-reload="forceReload"
                  @forced="onForceDone()"
                  @selected="onSelectionDone($event)"
                  @loaded="activeDates = $event.dates"
                ></agenda-dates-table>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
      <div v-if="!isLoading(loadingName)" ref="excelMe">
        <b-row>
          <b-col></b-col>
          <b-col cols="3" class="no-print">
            <b-form-group
              id="display-mode-group"
              label-for="display-mode"
            >
              <b-form-select
                id="display-mode"
                v-model="displayMode"
              >
                <b-form-select-option :value="null">Ne pas afficher la ville</b-form-select-option>
                <b-form-select-option value="address">Afficher l'adresse</b-form-select-option>
                <b-form-select-option value="city">Afficher la ville</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div ref="printMe">
          <div class="school-years">
            <div v-if="schoolYears.length === 0">Aucune année scolaire sélectionnée</div>
            <div class="badge badge-dark" v-for="schoolYear of schoolYears" :key="schoolYear.id">
              {{ schoolYear.name }}
            </div>
          </div>
          <x-table
            :columns="columns"
            :items="items"
            :show-counter="true"
            verbose-name="animateur"
          ></x-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import AgendaDatesTable from '@/components/Agenda/AgendaDatesTable.vue'
import { BackendMixin } from '@/mixins/backend'
import { AgendaMixin } from '@/mixins/agenda'
import { ActivitiesMixin } from '@/mixins/activities'
import router from '@/router'
import { makeActivity } from '@/types/activities'
import { makeIndividual, makeEntity } from '@/types/people'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'

export default {
  name: 'activity',
  props: {
    activityId: String,
    youthHome: [String, Number],
  },
  mixins: [BackendMixin, ActivitiesMixin, AgendaMixin],
  components: {
    AgendaDatesTable,
    XTable,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'activityManagers',
      activityManagers: [],
      schoolYears: [],
      displayMode: null,
      showAgenda: false,
      selectedManager: null,
      dates: [],
    }
  },
  async created() {
    await this.loadSchoolYears()
    await this.loadData()
  },
  watch: {
  },
  computed: {
    title() {
      let text = 'Animateurs des activités'
      const menu = this.getActivityMenu()
      if (menu && menu.category) {
        text += ' ' + menu.label
      }
      return text
    },
    pageLinks() {
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: 'btn-secondary',
        },
        {
          id: 3,
          label: this.showAgenda ? 'Liste' : 'Agenda',
          icon: this.showAgenda ? 'fa fa-table' : 'fa fa-calendar-alt',
          cssClass: 'btn-secondary',
          callback: this.toggleAgenda,
        }
      ]
    },
    columns() {
      let columns = [
        {
          name: 'lastName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'families-detail',
                params: { entityId: item.entityId, },
                query: { tab: this.youthHome ? 'youth' : 'activities', },
              }
            ).href
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.individual)
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'families-detail',
                params: { entityId: item.entityId, },
                query: { individual: item.individualId, },
              }
            ).href
          },
        },
        { name: 'email', label: 'Email', displayAs: 'email', },
        { name: 'proPhone', label: 'Téléphone pro.', displayAs: 'phone', },
        { name: 'cellPhone', label: 'Téléphone mobile', displayAs: 'phone', },
        { name: 'activities', label: 'Activités', maxWidth: '200px', }
      ]
      if (this.displayMode === 'address') {
        columns.push({ name: 'address', label: 'Adresse', })
        columns.push({ name: 'zipCode', label: 'Code postal', })
        columns.push({ name: 'city', label: 'Ville', })
      } else if (this.displayMode === 'city') {
        columns.push({ name: 'city', label: 'Ville', })
      }
      return columns
    },
    items() {
      return this.activityManagers.map(elt => this.makeItem(elt))
    },
    canView() {
      return this.hasPerm('activities.view_activitymanager')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    toggleAgenda() {
      this.showAgenda = !this.showAgenda
    },
    async loadData() {
      this.startLoading(this.loadingName)
      try {
        const url = '/api/activity-managers/?category=' + this.category
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activityManagers = resp.data.map(
          elt => {
            return {
              individual: makeIndividual(elt.individual),
              entity: makeEntity(elt.entity),
              activities: elt.activities.map(subElt => makeActivity(subElt)),
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = this.selectedManager ? slugify(this.selectedManager.individual.firstAndLastName()) : 'animateurs'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    makeActivityItem(activity, youth) {
      const url = router.resolve(this.getLinkToActivity(activity, youth)).href
      return '<a href="' + url + '" target="_blank">' + activity.name + '</a>'
    },
    makeItem(elt) {
      return {
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        email: elt.individual.email,
        cellPhone: elt.individual.cellPhone,
        proPhone: elt.individual.proPhone,
        individual: elt.individual,
        entity: elt.entity,
        address: elt.entity.fullAddress(),
        city: elt.entity.city.name,
        zipCode: elt.entity.zipCode,
        activities: elt.activities.map(
          activity => this.makeActivityItem(activity, activity.youth)
        ).join(', '),
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = this.selectedManager ? slugify(this.selectedManager.individual.firstAndLastName()) : 'animateurs'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToActivity(activity, youth) {
      if (youth) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    selectManager(manager) {
      this.selectedManager = manager
    },
  },
}
</script>

<style scoped lang="less">
.school-years {
  background: #eee;
}
.school-years .badge {
  display: inline-block;
  margin-right: 2px;
}
.selected-manager {
  padding: 10px;
}
</style>
